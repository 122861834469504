import React, { Component } from 'react'

import { MovingText, Measure, MovingTextEcho } from './styles'

/* Font metrics, used to calculate exact height */
const capitalHeight = 0.71
const descender = 0.095
const ascender = 0.71

export default class Text extends Component {
  state = {
    height: 0,
    width: 0,
    windowWidth: 0,
    windowHeight: 0,
    letterWidth: 0,
    fontSize: 0,
    lineHeight: 0,
    time: 0
  }

  componentDidMount () {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    // stop aniamtion
    document
      .querySelectorAll('.moving-text')
      .forEach(el => el.classList.remove('animate'))

    // get new height
    this.state.time = setTimeout(() => {
      const windowHeight = window.innerHeight
      const computedFontSize = windowHeight / capitalHeight - 50
      const distanceBottom = descender
      const distanceTop = ascender - capitalHeight
      const valign = (distanceBottom - distanceTop) * computedFontSize
      const lineHeight = windowHeight

      this.setState(
        {
          windowWidth: window.innerWidth,
          windowHeight: windowHeight,
          fontSize: computedFontSize,
          lineHeight: lineHeight
        },
        () => {
          const elem = document.getElementById(this.props.text)
          this.setState({
            height: elem.clientHeight,
            width: elem.clientWidth,
            letterWidth: elem.clientWidth / this.props.text.length / 2
          })
          document
            .querySelectorAll('.moving-text')
            .forEach(el => el.classList.add('animate'))
        }
      )
    }, 100)
  }

  render () {
    const { text } = this.props
    const {
      height,
      width,
      windowWidth,
      letterWidth,
      fontSize,
      lineHeight
    } = this.state
    const lineWidth = 2 * width + 2 * letterWidth - windowWidth
    const duration = this.props.text.length * 2
    return (
      <div style={{ minHeight: '100vh' }}>
        <Measure fontSize={fontSize} lineHeight={lineHeight} id={text}>
          {text}
        </Measure>
        <div style={{ position: 'relative' }}>
          <MovingText
            fontSize={fontSize}
            lineHeight={lineHeight}
            height={height}
            width={lineWidth}
            windowWidth={windowWidth}
            duration={duration}
            delay={-windowWidth / lineWidth * duration}
          >
            <h1 className='moving-text animate'>{text}</h1>
          </MovingText>
          <MovingTextEcho
            fontSize={fontSize}
            lineHeight={lineHeight}
            height={height}
            width={lineWidth}
            windowWidth={windowWidth}
            duration={duration}
            delay={
              (width + letterWidth - windowWidth / 2 - windowWidth) /
              lineWidth *
              duration
            }
          >
            <h1 className='moving-text animate'>{text}</h1>
          </MovingTextEcho>
        </div>
      </div>
    )
  }
}
