import styled from 'styled-components'

export const Measure = styled.h1`
  z-index: 0;
  position: absolute;
  overflow: hidden;
  visibility: hidden;
  height: auto;
  width: auto;
  margin: 0;

  font-family: 'Tungsten Narrow A', 'Tungsten Narrow B';
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : `0px`)};
  line-height: ${props => (props.lineHeight ? `${props.lineHeight}px` : `0px`)};

  letter-spacing: -3.84px;
  white-space: nowrap;
`

export const MovingText = styled.div`
  z-index: 0;
  height: ${props => (props.height ? `${props.height}px` : `0px`)};
  width: ${props => (props.width ? `${props.width}px` : `0px`)};
  user-select: none;
  overflow: hidden;
  position: relative;

  & > h1 {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: -10px;
    margin: 0;

    font-family: 'Tungsten Narrow A', 'Tungsten Narrow B';
    font-size: ${props => (props.fontSize ? `${props.fontSize}px` : `0px`)};
    line-height: ${props =>
    props.lineHeight ? `${props.lineHeight}px` : `0px`};
    letter-spacing: -3.84px;
    white-space: nowrap;
    word-break: normal;

    /* Starting position */
    -moz-transform: ${props => `translateX(${props.windowWidth}px)`};
    -webkit-transform: ${props => `translateX(${props.windowWidth}px)`};
    transform: ${props => `translateX(${props.windowWidth}px)`};

    /* Apply animation to this element */
    &.animate {
      -moz-animation: moveText
        ${props => (props.duration ? `${props.duration}s` : `40s`)} linear
        infinite;
      -webkit-animation: moveText
        ${props => (props.duration ? `${props.duration}s` : `40s`)} linear
        infinite;
      animation: moveText
        ${props => (props.duration ? `${props.duration}s` : `40s`)} linear
        infinite;
      -webkit-animation-delay: ${props => `${props.delay}s`};
      animation-delay: ${props => `${props.delay}s`};
    }
  }

  @-moz-keyframes moveText {
    0% {
      -moz-transform: ${props => `translateX(${props.windowWidth}px)`};
    }
    100% {
      -moz-transform: translateX(-100%);
    }
  }
  @-webkit-keyframes moveText {
    0% {
      -webkit-transform: ${props => `translateX(${props.windowWidth}px)`};
    }
    100% {
      -webkit-transform: translateX(-100%);
    }
  }

  @keyframes moveText {
    0% {
      -moz-transform: ${props =>
    `translateX(${props.windowWidth}px)`}; /* Firefox bug fix */
      -webkit-transform: ${props =>
    `translateX(${props.windowWidth}px)`}; /* Firefox bug fix */
      transform: ${props => `translateX(${props.windowWidth}px)`};
    }
    100% {
      -moz-transform: translateX(-100%); /* Firefox bug fix */
      -webkit-transform: translateX(-100%); /* Firefox bug fix */
      transform: translateX(-100%);
    }
  }
`

export const MovingTextEcho = styled(MovingText)`
  position: absolute;
  top: 0;
  & > h1.animate {
    -moz-animation: moveText
      ${props => (props.duration ? `${props.duration}s` : `40s`)} linear
      infinite;
    -webkit-animation: moveText
      ${props => (props.duration ? `${props.duration}s` : `40s`)} linear
      infinite;
    animation: moveText
      ${props => (props.duration ? `${props.duration}s` : `40s`)} linear
      infinite;
    -webkit-animation-delay: ${props => `${props.delay}s`};
    animation-delay: ${props => `${props.delay}s`};
  }
`
