import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export const ArrowContainer = styled(AnchorLink)`
  z-index: 3;
  position: absolute;
  top: ${({ right }) => (right ? `unset;` : `calc(100vh - 35px - 44px);`)};
  bottom: ${({ right }) => (right ? `35px;` : `unset;`)};
  left: ${({ right }) => (right ? `calc(100% - 35px - 29px);` : `35px;`)};

  img {
    margin-bottom: 0;
  }
  font-size: 0;
  line-height: 0;
  transition: opacity 0.5s;
  opacity: ${({ scrolled }) => scrolled && `0;`};
  pointer-events: ${({ scrolled }) => (scrolled ? `none;` : `default;`)};
`
