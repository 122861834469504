import React, { Component } from 'react'

import Hover from '../Hover'

import { ArrowContainer } from './styles'

export default class Arrow extends Component {
  state = { scrolledDown: false }

  componentDidMount () {
    !this.props.right && window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount () {
    !this.props.right && window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    this.setState({
      scrolledDown:
        document.body.scrollTop > 0 || document.documentElement.scrollTop > 0
    })
  }

  render () {
    const { img, imgHover, href, right } = this.props
    return (
      <ArrowContainer
        right={right ? 'true' : undefined}
        scrolled={this.state.scrolledDown ? 'true' : undefined}
        href={href}
      >
        <Hover onHover={<img src={imgHover} />}>
          <img src={img} />
        </Hover>
      </ArrowContainer>
    )
  }
}
