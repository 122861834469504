import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import ScrollAnimation from 'react-animate-on-scroll'

import Layout from '../components/Layout'
import MovingText from '../components/MovingText'
import {
  Container,
  ImgGlobe,
  ImgPortrait,
  Images
} from '../components/Content/styles'

import Arrow from '../components/Arrow'
import arrowDown from '../assets/icons/nav_arrow_down.svg'
import arrowDownHover from '../assets/icons/nav_arrow_down_hover.svg'
import arrowUp from '../assets/icons/nav_arrow_up.svg'
import arrowUpHover from '../assets/icons/nav_arrow_up_hover.svg'

import { HTMLContent } from '../components/Content'
import Carousel from '../components/Carousel'
import globeBrackets from '../assets/icons/globe_new.svg'

export class HomePageTemplate extends Component {
  state = { currentSlide: 0 }

  componentDidMount () {
    const arr = window.location.href.split('#')
    const gallery_project = arr[arr.length - 1]
    if (gallery_project) {
      const arr2 = gallery_project.split('-')
      if (arr2.length === 2) {
        const gallery = arr2[0]
        const project = arr2[1]
        if (gallery === 'gallery') {
          this.setState(
            { currentSlide: project ? parseInt(project) : 0 },
            () => {
              document.getElementById('gallery_trigger').click()
            }
          )
        }
      }
    }
  }

  handleSlideChange = i => {
    this.setState({ currentSlide: i })
  }

  render () {
    const { home, projects } = this.props
    return (
      <React.Fragment>
        <div id='top' />
        <Arrow href='#contact' img={arrowDown} imgHover={arrowDownHover} />
        <MovingText text={home.frontmatter.mt_top} />

        <ScrollAnimation animateIn='fadeInUp' animateOnce>
          <Container className='pt'>
            <Images>
              <ImgPortrait
                fluid={home.frontmatter.portrait.childImageSharp.fluid}
              />
              <ImgGlobe src={globeBrackets} />
            </Images>
          </Container>
        </ScrollAnimation>

        <Container>
          <ScrollAnimation animateIn='fadeInUp' animateOnce>
            <h1>{home.frontmatter.main_paragraph_title}</h1>
          </ScrollAnimation>
          <br />
          <ScrollAnimation animateIn='fadeInUp' animateOnce>
            <HTMLContent content={home.html} />
          </ScrollAnimation>
        </Container>
        <ScrollAnimation animateIn='fadeInUp' animateOnce>
          <Container className='cards'>
            {home.frontmatter.paragraph_2.map((elem, i) => (
              <div key={i}>
                <h2>{elem.title}</h2>
                <p>{elem.text}</p>
              </div>
            ))}
            <div id='gallery' style={{ position: 'relative', top: '-100px' }} />
            <a id='gallery_trigger' href='#gallery' />
          </Container>
        </ScrollAnimation>

        <ScrollAnimation animateIn='fadeInUp' animateOnce>
          <Carousel
            order={home.frontmatter.projects}
            projects={projects}
            handleSlideChange={this.handleSlideChange}
            selectedSlide={this.state.currentSlide}
          />
        </ScrollAnimation>

        <ScrollAnimation animateIn='fadeInUp' animateOnce>
          <Container id='contact'>
            <h1>
              {home.frontmatter.paragraph_3.text}
              <br />
              {home.frontmatter.paragraph_3.email && (
                <a href={`mailto:${home.frontmatter.paragraph_3.email}`}>
                  {home.frontmatter.paragraph_3.email}
                </a>
              )}
            </h1>
            <div className='links'>
              {home.frontmatter.paragraph_3.links.map(
                (link, i) =>
                  link.type === 'page' ? (
                    <Link key={i} to={link.path}>
                      {link.name}
                    </Link>
                  ) : (
                    <a
                      key={i}
                      href={
                        link.type === 'email'
                          ? `mailto:${link.path}`
                          : link.path
                      }
                    >
                      {link.name}
                    </a>
                  )
              )}
            </div>
          </Container>
        </ScrollAnimation>

        <Arrow right href='#top' img={arrowUp} imgHover={arrowUpHover} />
        <MovingText text={home.frontmatter.mt_bottom} />
      </React.Fragment>
    )
  }
}

const HomePage = ({ data }) => {
  const { home, projects } = data
  return (
    <Layout pageTitle={home.frontmatter.title}>
      <HomePageTemplate home={home} projects={projects} />
    </Layout>
  )
}

export default HomePage

export const HomePageQuery = graphql`
  query HomePage($id: String!) {
    home: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        mt_top
        mt_bottom
        main_paragraph_title
        portrait {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        paragraph_2 {
          title
          text
        }
        projects {
          project
        }
        paragraph_3 {
          text
          email
          links {
            name
            type
            path
          }
        }
      }
    }
    projects: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "project-page" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            project_id
            title
            image {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
